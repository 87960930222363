.cursor4 {
 -webkit-animation: blink 1s infinite;
 -moz-animation: blink 1s infinite;
 -o-animation: blink 1s infinite;
 animation: blink 1s infinite;
}

@-webkit-keyframes blink {
 0% {
  opacity: 0;
 }
 40% {
  opacity: 0;
 }
 50% {
  opacity: 1;
 }
 90% {
  opacity: 1;
 }
 100% {
  opacity: 0;
 }
}

@-moz-keyframes blink {
 0% {
  opacity: 0;
 }
 40% {
  opacity: 0;
 }
 50% {
  opacity: 1;
 }
 90% {
  opacity: 1;
 }
 100% {
  opacity: 0;
 }
}

@-o-keyframes blink {
 0% {
  opacity: 0;
 }
 40% {
  opacity: 0;
 }
 50% {
  opacity: 1;
 }
 90% {
  opacity: 1;
 }
 100% {
  opacity: 0;
 }
}

@keyframes blink {
 0% {
  opacity: 0;
 }
 40% {
  opacity: 0;
 }
 50% {
  opacity: 1;
 }
 90% {
  opacity: 1;
 }
 100% {
  opacity: 0;
 }
}
